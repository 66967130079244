<template>
  <div class="fields-category-setting" v-if="getCategoriesCategorie">
    <div>
      <v-row class="">
        <v-col cols="6">
          <div class="style-title-setting-global">
            <span
              class="bold-700 mr-2 title-hover cursor-pointer"
              @click="handelCancel($event)"
            >
              {{ $i18n.locale == 'fr' ? 'Sous catégories' : 'Sub category' }}
              <v-icon class="color-crm">mdi-chevron-right</v-icon></span
            >
            <span
              class="color-crm font-sz-18 bold-500 font-text text-capitalize"
              >{{
                getCategoriesCategorie && getCategoriesCategorie.name
                  ? getCategoriesCategorie.name
                  : getCategoriesCategorie
              }}</span
            >
          </div>
          <div class="message mt-2">
            <div v-if="getCategorieError" class="error-msg">
              <ul v-if="Array.isArray(getCategorieError)">
                <li v-for="(e, index) in getCategorieError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getCategorieError }}</span>
            </div>
          </div>
        </v-col>
        <v-col>
          <v-progress-circular
            v-if="getCategorieLoading && !initLoading"
            class="float-right mt-1 mr-2"
            indeterminate
            color="#5C2DD3"
          ></v-progress-circular
        ></v-col>
        <v-col>
          <v-btn
            class="btn-filter float-right"
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleClickBtnAddFields"
          >
            <v-icon class="sz-icon-plus mr-1">mdi-plus</v-icon>
            {{ $i18n.locale == 'fr' ? 'Ajouter des champs' : 'Add fields' }}
          </v-btn></v-col
        >
      </v-row>
    </div>
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div class="content mt-2" v-else>
      <div
        v-if="
          getCategoriesCategorie &&
            (!getCategoriesCategorie.fields ||
              !getCategoriesCategorie.fields.length)
        "
        class="div-aucun-list"
      >
        <div class="titre">
          {{
            $i18n.locale == 'fr'
              ? 'Aucun champ disponible pour cette sous-catégorie'
              : 'No fields available for this sub-category'
          }}
        </div>
      </div>
      <div class="card-scene" ref="cardSceneCategory" v-else>
        <Container
          :drop-placeholder="dropPlaceholderOptions"
          :lock-axis="'y'"
          @drop="onColumnDrop"
          drag-handle-selector=".column-drag-container "
        >
          <Draggable
            v-for="champ in getCategoriesCategorie.fields"
            :key="champ.id"
          >
            <div class="mt-1">
              <div class="card-column-header">
                <div class="input-title-sous-type">
                  <v-row>
                    <v-col cols="5">
                      <v-text-field
                        :label="$t('designation')"
                        dense
                        @change="updateNameChamp($event, champ)"
                        :title="
                          $i18n.locale === 'fr'
                            ? 'Modifier le nom de champ'
                            : 'Modify field name'
                        "
                        :placeholder="
                          $i18n.locale === 'fr' ? 'Nom de champ' : 'Field name'
                        "
                        :value="champ.name"
                        :persistent-placeholder="true"
                        outlined
                        color="#5C2DD3"
                        item-color="#5C2DD3"
                        disabled
                      >
                        <template #prepend>
                          <v-icon
                            class="cursor-move mr-2 ml-2 bold-700 color-crm font-sz-30 mb-1"
                            :class="{
                              'column-drag-container':
                                getCategoriesCategorie &&
                                getCategoriesCategorie.fields &&
                                getCategoriesCategorie.fields.length > 1
                            }"
                            >mdi-drag-horizontal-variant</v-icon
                          >
                        </template>
                      </v-text-field></v-col
                    >
                    <v-col cols="6">
                      <v-switch
                        class="input-checkbox switch-bottom label-switch float-right mt-2"
                        v-model="champ.subcategory_header"
                        @change="updateEnteteChamp($event, champ)"
                        color="#5C2DD3"
                        :label="
                          $i18n.locale == 'fr' ? 'Champ entête' : 'Header field'
                        "
                        hide-details
                      >
                      </v-switch>
                    </v-col>
                    <v-col cols="1">
                      <v-btn
                        class="font-sz-30 bold-700 float-right color-crm"
                        @click="tryDeleteChamp(champ)"
                        :title="
                          $i18n.locale == 'fr'
                            ? 'Supprimer un champ'
                            : 'Delete field'
                        "
                        fab
                        small
                        icon
                      >
                        <v-icon>mdi-close-circle-outline</v-icon></v-btn
                      ></v-col
                    >
                  </v-row>
                </div>
              </div>
            </div>
          </Draggable>
        </Container>
      </div>
    </div>

    <!-- Deleted champs -->
    <v-dialog v-model="deleteChampModal" max-width="700" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ $i18n.locale == 'fr' ? 'Supprimer un champ' : 'Delete field' }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('deleteChampModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p class="mb-3">
            {{
              $t('msgdeleted', {
                msg: $i18n.locale === 'fr' ? 'le champ' : 'the field'
              })
            }}
            <strong>
              {{ champToDelete ? champToDelete.name : '' }}
            </strong>
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getCategorieLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getCategorieError" class="error-msg">
              <ul v-if="Array.isArray(getCategorieError)">
                <li v-for="(e, index) in getCategorieError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getCategorieError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="deleteChamp"
            :loading="getCategorieLoading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('deleteChampModal')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add Champ to Sous_Categorie Modal -->
    <v-dialog
      v-model="addChampToSubCateg"
      max-width="900"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ $i18n.locale == 'fr' ? 'Ajouter des champs' : 'Add field' }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('addChampToSubCateg')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="addChampToSubCateg" class="mt-2">
            <v-row v-if="visibilityChoixFieldContactAndProjet">
              <v-col class="flex center">
                <v-checkbox
                  class="input-checkbox msg-error mt-2 label-check mr-3"
                  v-for="(item, index) in computedgetCustomFieldsMenu"
                  :key="index"
                  :value="item"
                  v-model="ListChampToAdd.type"
                  @change="changeTypeInModal(item)"
                  :label="translate(item.text, $i18n.locale)"
                  color="#5C2DD3"
                >
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row class="mt-2">
              <v-col>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  multiple
                  chips
                  return-object
                  :deletable-chips="true"
                  :small-chips="true"
                  :placeholder="$t('searchMsg', { msg: $t('champ') })"
                  item-color="#5C2DD3"
                  v-model="ListChampToAdd.listChamp"
                  :items="getCustomFieldsCategorie"
                  :persistent-placeholder="true"
                  :label="
                    $i18n.locale === 'fr'
                      ? 'Liste des champs'
                      : 'List of fields'
                  "
                  :item-text="nameWithLang"
                  :loading="getCategorieLoading"
                  dense
                  item-value="name"
                  :no-data-text="$t('noDataOptionMsg', { option: $t('champ') })"
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      (v && v.length > 0) ||
                      $t('msgObligMsg', { msg: $t('champ') })
                  ]"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getCategorieLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getCategorieError" class="error-msg">
              <ul v-if="Array.isArray(getCategorieError)">
                <li v-for="(e, index) in getCategorieError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getCategorieError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="addChamps"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('addChampToSubCateg')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { Container, Draggable } from 'vue-smooth-dnd'
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      initLoading: true,
      selectedCategorie: null,
      ListChampToAdd: {
        type: [],
        listChamp: []
      },
      customizable: 1,
      dropPlaceholderOptions: {
        animationDuration: '150',
        showOnTop: true
      },
      champToDelete: null,
      addChampToSubCateg: false,
      deleteChampModal: false,
      loading: false
    }
  },

  methods: {
    ...mapActions([
      'resetErrorCategorie',
      'fetchAllTablesCustomFields',
      'fetchListChampforSubCategorie',
      'resetCategorieListCustemFields',
      'updateCustemFieldSubCategorie',
      'fechlistFieldSubCategorie',
      'addCutemFieldsToSubCategorie',
      'fetchOneSubCategorie',
      'updateOrderCustemFieldsSubCategorie',
      'deleteCustemFieldSubCategorie'
    ]),
    async handleClickBtnAddFields() {
      this.addChampToSubCateg = true
      // table projet 42 et projet immo
      if (localStorage.getItem('typeProject') == 3) {
        await this.fetchListChampforSubCategorie({
          type: this.computedgetCustomFieldsMenu.filter(
            item => item.value == 42
          ),
          idSubCat: this.$route.params.idSubCategorie
        })
      }
    },
    handelCancel() {
      if (this.$route && this.$route.path) {
        let path = this.$route.path.replace(/\/subCategorie\/.*$/, '')
        this.$router.push(path)
      }
    },
    closeDialog(ref) {
      this[ref] = false
      if (ref == 'addChampToSubCateg') {
        this.$refs.addChampToSubCateg.resetValidation()
      }
      this.resetModal()
    },
    resetModal() {
      this.champToDelete = null
      this.ListChampToAdd = {
        type: [],
        listChamp: []
      }
      this.resetErrorCategorie()
      this.resetCategorieListCustemFields()
    },
    //change type in modal
    async changeTypeInModal() {
      if (this.ListChampToAdd.type) {
        await this.fetchListChampforSubCategorie({
          type: this.ListChampToAdd.type,
          idSubCat: this.$route.params.idSubCategorie
        })
      }
    },
    //add champ
    async addChamps() {
      if (this.$refs.addChampToSubCateg.validate()) {
        this.loading = true
        const responce = await this.addCutemFieldsToSubCategorie({
          idSubCat: this.$route.params.idSubCategorie,
          ChampsToAdd: this.ListChampToAdd.listChamp
        })
        if (responce) {
          // scroll when add new champ
          this.$nextTick(() => {
            this.$refs.cardSceneCategory.scrollTop = this.$refs.cardSceneCategory.scrollHeight
            this.closeDialog('addChampToSubCateg')
          })
        }
        this.loading = false
      }
    },
    //updated name Champ
    updateNameChamp(e, champ) {
      this.updateCustemFieldSubCategorie({
        idCategorie: this.getCategoriesCategorie.id,
        champ: champ,
        data: { name: e }
      })
    },
    //update champ entet
    updateEnteteChamp(e, champ) {
      this.updateCustemFieldSubCategorie({
        idCategorie: this.getCategoriesCategorie.id,
        champ: champ,
        data: { is_subcategory_header: e ? 1 : 0 }
      })
    },

    //show modal delete Champ
    tryDeleteChamp(champ) {
      this.champToDelete = champ
      this.deleteChampModal = true
    },
    //delete subCategorieChamp
    async deleteChamp() {
      const response = await this.deleteCustemFieldSubCategorie({
        idCategorie: this.getCategoriesCategorie.id,
        champ: this.champToDelete
      })
      if (response) {
        this.closeDialog('deleteChampModal')
      }
    },
    onColumnDrop(dropResult) {
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }
      let custemFieldsOrder = this.applyDrag(
        this.getCategoriesCategorie.fields,
        dropResult
      )
      this.updateOrderCustemFieldsSubCategorie(custemFieldsOrder)
      this.updateCustemFieldSubCategorie({
        idCategorie: this.getCategoriesCategorie.id,
        champ: this.getCategoriesCategorie.fields[addedIndex],
        data: {
          order: addedIndex
        }
      })
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr
      const result = [...arr]
      let itemToAdd = payload
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }
      for (let index = 0; index < result.length; index++) {
        result[index].order = index
      }
      return result
    },
    nameWithLang({ name }) {
      return `${name}`
    }
  },
  components: {
    Container,
    Draggable
  },
  computed: {
    ...mapGetters([
      'getCategorieLoading',
      'getCategorieError',
      'getCustomFieldsMenu',
      'getCustomFieldsCategorie',
      'getCategoriesCategorie'
    ]),
    visibilityChoixFieldContactAndProjet: function() {
      // projet_immo_id ==3
      if (localStorage.getItem('typeProject') != 3) {
        return true
      }
      return false
    },
    translate() {
      return function(data, t) {
        if (data == 'projets') {
          return t === 'fr' ? 'Projets' : 'Project'
        } else if (data == 'contacts') {
          return t === 'fr' ? 'Contacts' : 'Contacts'
        } else if (data == 'type_ressources') {
          return t === 'fr' ? 'Types ressources' : 'Resource type'
        } else if (data == 'type_events') {
          return t === 'fr' ? 'Types événements' : 'Event type'
        } else {
          return data
        }
      }
    },
    computedgetCustomFieldsMenu() {
      let table = []
      table = this.getCustomFieldsMenu.map(item => {
        return { text: item.name, value: item.id }
      })
      return table
    }
  },
  async mounted() {
    this.initLoading = true
    await this.fetchOneSubCategorie({
      idCategorie: this.$route.params.id,
      idSubCategorie: this.$route.params.idSubCategorie
    })
    if (!this.getCategoriesCategorie) {
      this.$router.push('/setting/categorie')
    }
    await this.fetchAllTablesCustomFields({
      customizable: this.customizable
    })
    this.fechlistFieldSubCategorie(this.$route.params.idSubCategorie)

    this.initLoading = false
  }
}
</script>

<style lang="scss" scoped>
.fields-category-setting {
  .block-menu-users {
    .list-tabs {
      background-color: #f6f6f6;
      display: flex;
      align-items: center;
    }
    .v-tab {
      &:hover {
        text-decoration: none;
      }
    }
    v-tab .v-tab--active {
      &:hover {
        text-decoration: none;
      }
    }
    .menu-item {
      &.router-link-exact-active {
        text-decoration: none;
        font-weight: 700;
        font-size: 14px;
        color: #5c2dd3;
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
    }
    .v-tab {
      text-decoration: none;
      font-weight: 600;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      letter-spacing: 0.4px;
      font-family: 'Montserrat', sans-serif;
      &.v-tab--active {
        text-decoration: none;
        font-weight: 700;
        font-size: 12px;
        color: #5c2dd3;
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
      &.v-tab--disabled {
        text-decoration: none;
        font-weight: 600;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
    }
  }
  .list-fildes-type-projet {
    max-height: calc(100vh - 400px) !important;
    height: calc(100vh - 400px) !important;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: auto;
    padding: 0px 0px;
    cursor: pointer;
  }
  .list-fildes-type-projet::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .list-fildes-type-projet::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .list-fildes-type-projet::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(189, 189, 189, 1);
  }
  .form-actions {
    float: right;
    margin-top: 10px;
  }
  // STYLE BLOCK DRAG AND DROP
  .column-drag-handle,
  .ss-column-drag-handle {
    position: absolute;
    top: 9px;
    left: 6px;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ss-column-drag-handle {
    top: 5px;
    left: 3px;
  }

  .input-title-sous-type {
    display: flex;
    justify-content: space-between;
    // margin-left: 25px;
    width: 100%;
    .body-drag {
      display: -webkit-box;
      justify-content: space-between;
      input {
        color: black;
        overflow: visible;
        background-color: transparent;
        border: 0px;
        font-size: 15px;
        text-overflow: ellipsis;
      }
      .custom-select-sm {
        width: 139px;
      }
    }
  }
  .icon-sous-categorie {
    .btn-left {
      color: #495057ab;
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
      margin-right: 3px;
    }
    .btn-secondary:focus,
    .btn-secondary.focus {
      color: #495057ab;
      background-color: rgba(255, 255, 255, 0.075);
      border-color: rgba(255, 255, 255, 0.075);
    }
  }
  .card-column-header {
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 9px;
  }
  .smooth-dnd-container.horizontal {
    display: flex;
  }
  .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
    position: relative;
    overflow: visible;
    margin-top: 13px;
    border: 2px solid #5c2dd3;
    padding: 4px;
    background-color: rgba(214, 204, 242, 0.34);
    width: 100%;
    border-radius: 4px;
    border-left: 14px solid #5c2dd3;
  }
  .smooth-dnd-container {
    margin: 3px;
  }
  .smooth-dnd-container {
    margin: 0px 6px 0px 0px;
    position: relative;
    min-height: 30px;
    min-width: 255px;
  }
  .smooth-dnd-container.horizontal > .smooth-dnd-draggable-wrapper {
    border: 1px solid #6c757d4d;
    margin-right: 8px;
    background-color: #ebebeb5c;
    border-radius: 4px;
  }
  .custom-select-sm {
    height: calc(1.5em + 0.5rem + 7px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 12px;
    width: 77px;
  }
}
</style>
<style lang="scss">
.fields-category-setting {
  .input-title-sous-type {
    .v-text-field.v-text-field--enclosed .v-text-field__details {
      display: none !important;
    }
  }
  .card-scene {
    max-height: calc(100vh - 244px) !important;
    height: calc(100vh - 244px) !important;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: auto;
    padding: 0px 0px;
    cursor: pointer;
  }
  .card-scene::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .card-scene::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .card-scene::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(189, 189, 189, 1);
  }
  .scroll {
    max-height: calc(100vh - 300px) !important;
    height: calc(100vh - 300px) !important;
  }
}
</style>
